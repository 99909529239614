@import '../../common/variables.css';
@import '../../common/mixins/font.css';
@import '../../common/mixins/text.css';

.badge {
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
  @mixin text-tiny;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 4px;
  color: #fff;
  font-weight: 600;
}

.badge--variant-default {
  background-color: var(--color-dark-gray);
}
.badge--variant-disabled {
  background-color: var(--color-light-gray);
}
.badge--variant-purple {
  background-color: var(--color-purple);
}
.badge--variant-teal {
  background-color: var(--color-teal);
}
.badge--variant-yellow {
  background-color: var(--color-yellow);
}
.badge--variant-orange {
  background-color: var(--color-orange);
}
.badge--variant-neutral {
  background-color: var(--color-neutral);
}
.badge--variant-accepted {
  background-color: var(--color-accepted);
}
.badge--variant-critical {
  background-color: var(--color-critical);
}
.badge--variant-warning {
  background-color: var(--color-warning);
}
