@import '../../common/variables.css';

.link {
  color: var(--color-text-link);

  &:focus-visible {
    outline: var(--outline);
    outline-offset: var(--outline-offset-small);
  }

  &,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: var(--color-text-link-hover);
  }

  &:active,
  &:visited {
    color: var(--color-text-link-pressed);
  }
}
