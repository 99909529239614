@import '../../common/variables.css';

.wrapper {
  /* Enable animation */
  --pseudo-element-display: block;

  box-sizing: border-box;
  width: 100%;
  display: flex;
  position: relative;
  user-select: none;
  overflow: hidden;
  /* Necessary for overflow: hidden to work correctly in Safari */
  z-index: 1;
  background-color: var(--color-background-gray-2);
  border-radius: 8px;

  &::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--color-background-gray-2),
      var(--color-background-gray-0),
      var(--color-background-gray-2)
    );
    transform: translateX(-100%);
    animation-name: loading;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @media (prefers-reduced-motion) {
    /* Disable animation */
    --pseudo-element-display: none;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
