.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0;
  background: none;
  border: none;
}

.wrapper > * {
  margin-block: 0;
}

.spacing {
  & > * + * {
    margin-block-start: var(--spacing) !important;
  }
}

.recursive {
  & * + * {
    margin-top: var(--spacing) !important;
  }

  & > * {
    margin-block: auto !important;
  }
}

.exception {
  margin-block-start: var(--spacing) !important;
}
