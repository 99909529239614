@import '../../common/variables.css';
@import '../../common/mixins/mediaQuery.css';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;

  &:hover .button {
    @media (--hover-supported) {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
  }
}

.slider {
  display: flex;
  align-content: flex-start;
  overflow: hidden;
  position: relative;
  touch-action: pan-y;
  user-select: none;
  width: 100%;
}

.button {
  @media (--hover-supported) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all 0.5s;
  }
}

.buttonFadeOut {
  @media (--hover-supported) {
    opacity: 0 !important;
    visibility: visible !important;
    pointer-events: none !important;
  }
}
