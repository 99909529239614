@import '../../common/variables.css';

.card {
  border-radius: 8px;
  padding: 16px;
  box-shadow: var(--shadow);
}

.purple {
  background-color: var(--color-purple-10);
  border: 1px solid var(--color-purple);
}

.blue {
  border: 1px solid var(--color-neutral);
  background: var(--color-background-app);
}

.white {
  background-color: var(--color-white);
}

.top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
