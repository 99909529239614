@import '../../../common/variables.css';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 64px;
  height: 64px;
  cursor: pointer;
  background: var(--color-white-alpha50);
  border: 0.1px solid var(--color-dark-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-text);
}

.previous {
  left: 8px;
}

.next {
  right: 8px;
}
