@import '../../common/variables.css';
@import '../../common/mixins/mediaQuery.css';

.spinner {
  position: relative;
}

.spinnerSizeSmall {
  width: 24px;
  height: 24px;
}

.spinnerSizeMedium {
  width: 38px;
  height: 38px;
}

.spinnerSizeBig {
  width: 70px;
  height: 70px;
}

.spinnerCircle {
  border-radius: 50%;
  border: 3px solid;
  transform: translateZ(0);
  animation: rotate-circle 1.1s infinite linear;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @mixin hover-supported {
    pointer-events: none;
    cursor: none;
  }
}

.spinnerCirclePrimary {
  border-left-color: var(--color-white);
  border-top-color: var(--color-white-alpha20);
  border-right-color: var(--color-white-alpha20);
  border-bottom-color: var(--color-white-alpha20);
}

.spinnerCircleSecondary {
  border-left-color: var(--color-purple);
  border-top-color: var(--color-purple-alpha20);
  border-right-color: var(--color-purple-alpha20);
  border-bottom-color: var(--color-purple-alpha20);
}
.spinnerCircleTertiary {
  border-left-color: var(--color-teal);
  border-top-color: var(--color-teal-alpha20);
  border-right-color: var(--color-teal-alpha20);
  border-bottom-color: var(--color-teal-alpha20);
}

@keyframes rotate-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
