@import '../../common/variables.css';
@import '../../common/mixins/font.css';

.heading {
  margin: 0;
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
}

.headingTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.headingLarge {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.headingMedium {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.headingSmall {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.headingColorInherit {
  color: inherit;
}

.headingColorPrimary {
  color: var(--color-text);
}

.headingColorSecondary {
  color: var(--color-purple);
}

.headingColorSmart {
  color: var(--color-smart);
}

.headingColorBlue {
  color: var(--color-neutral);
}

.headingColorDisabled {
  color: var(--color-light-gray);
}

.headingAlignLeft {
  text-align: left;
}

.headingAlignRight {
  text-align: right;
}

.headingAlignCenter {
  text-align: center;
}

.headingAlignJustify {
  text-align: justify;
}
