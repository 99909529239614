@import '../../common/variables.css';
@import '../../common/mixins/font.css';
@import '../../common/mixins/text.css';

:root {
  --toast-width: 320px;
  --toast-min-height: 64px;
  --toast-max-height: 800px;
}

.container {
  z-index: var(--z-index-toast);
  position: fixed;
  padding: 4px;
  width: var(--toast-width);
  box-sizing: border-box;
  color: #fff;
}
.container:global(.Toastify__toast-container--top-left) {
  top: 1em;
  left: 1em;
}
.container:global(.Toastify__toast-container--top-center) {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.container:global(.Toastify__toast-container--top-right) {
  top: 1em;
  right: 1em;
}
.container:global(.Toastify__toast-container--bottom-left) {
  bottom: 1em;
  left: 1em;
}
.container:global(.Toastify__toast-container--bottom-center) {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.container:global(.Toastify__toast-container--bottom-right) {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .container:global(.Toastify__toast-container--top-left),
  .container:global(.Toastify__toast-container--top-center),
  .container:global(.Toastify__toast-container--top-right) {
    top: 0;
    transform: translateX(0);
  }
  .container:global(.Toastify__toast-container--bottom-left),
  .container:global(.Toastify__toast-container--bottom-center),
  .container:global(.Toastify__toast-container--bottom-right) {
    bottom: 0;
    transform: translateX(0);
  }
}

.toast {
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
  @mixin text-medium;
  position: relative;
  min-height: var(--toast-min-height);
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-light-gray);
  display: flex;
  justify-content: space-between;
  max-height: var(--toast-max-height);
  overflow: hidden;
  color: var(--color-text);
  border-left: 4px solid var(--color-light-gray);
  box-shadow: var(--shadow);
  background-color: #fff;
  cursor: default;
  /* webkit only issue #791 */
  z-index: 0;
  pointer-events: all;
}
.toast:global(.Toastify__toast--close-on-click) {
  cursor: pointer;
}
.toastBody {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.toastBody > div:last-child {
  word-break: break-word;
  -ms-flex: 1;
  flex: 1;
}
.toast :global(.Toastify__toast-icon) {
  margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

.container :global(.Toastify--animate) {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.container :global(.Toastify--animate-icon) {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width: 480px) {
  .toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.toast:global(.Toastify__toast--info) {
  border-left-color: #1b59ac;
}
.toast:global(.Toastify__toast--success) {
  border-left-color: #0fb91b;
}
.toast:global(.Toastify__toast--warning) {
  border-left-color: #ffc400;
}
.toast:global(.Toastify__toast--error) {
  border-left-color: #e21400;
}

.closeButton {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: #aaa;
  margin-top: -4px;
  margin-right: -4px;

  &:hover,
  &:focus {
    color: var(--color-teal);
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.container :global(.Toastify__bounce-enter--top-left),
.container :global(.Toastify__bounce-enter--bottom-left) {
  animation-name: bounceInLeft;
}
.container :global(.Toastify__bounce-enter--top-right),
.container :global(.Toastify__bounce-enter--bottom-right) {
  animation-name: bounceInRight;
}
.container :global(.Toastify__bounce-enter--top-center) {
  animation-name: bounceInDown;
}
.container :global(.Toastify__bounce-enter--bottom-center) {
  animation-name: bounceInUp;
}

.container :global(.Toastify__bounce-exit--top-left),
.container :global(.Toastify__bounce-exit--bottom-left) {
  animation-name: bounceOutLeft;
}
.container :global(.Toastify__bounce-exit--top-right),
.container :global(.Toastify__bounce-exit--bottom-right) {
  animation-name: bounceOutRight;
}
.container :global(.Toastify__bounce-exit--top-center) {
  animation-name: bounceOutUp;
}
.container :global(.Toastify__bounce-exit--bottom-center) {
  animation-name: bounceOutDown;
}

@keyframes trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

/**
 * We don't use progress bar at all,
 * but Toastify relies on its' `animation` and `transition` CSS properties
 * to `autoClose` the toast after a delay.
 **/
.container :global(.Toastify__progress-bar) {
  transform-origin: left;
}
.container :global(.Toastify__progress-bar--animated) {
  animation: trackProgress linear 1 forwards;
}
.container :global(.Toastify__progress-bar--controlled) {
  transition: transform 0.2s;
}
